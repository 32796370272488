<template>
  <div class="vlt-category-container">
    <div class="container-content">
      <div class="title-div">
        <div>
          <h5 class="content-title">{{ title }}</h5>
        </div>
        <div @click="actClose">
          <img
            class="close"
            src="@/assets/icons/detail_close_icon.svg"
            alt="vlite-logo"
            loading="lazy"
          />
        </div>
      </div>
      <div class="row" :class="displayLeft ? 'arrowAlignment' : ''">
        <div
          class="filter-menu"
        >
          <!-- class="lefty paddle arrow left-arrow" -->
          <div
            class="left-arrow-container arrow-container"
            :style="!displayLeft && { visibility: 'hidden', pointerEvents: 'none' }"
            @click="scrollLeft"
          >
            <div class="arrow left-arrow" id="left-button"></div>
          </div>
          <div class="outer category-parent" ref='categoryParent' id="content">
            <!-- filter contents-->
            <div class="slider" ref='categorySlider'
              :style="{
                transform: `translate3D( -${ categoryScroll }px , 0, 0 )`
              }"
            >
              <div
                v-if="filterOptions.length"
                @click="filterCategory( $event.currentTarget )"
                id="filterOptions-0"
                class="inner"
                :class ="{  'active' : !selectedCategory } "
              >
                <p>All</p>
              </div>
              <div
                class="inner"
                :class ="{  'active' : options.category === selectedCategory } "
                v-for="(options, index) in filterOptions"
                @click="
                  filterCategory( $event.currentTarget, options.category )
                "
                :key="index"
                :id="`filterOptions-${index + 1}`"
              >
                <p>{{ options.category }}</p>
              </div>
            </div>
          </div>

          <!-- class="righty paddle arrow right-arrow" -->
          <div
            class="right-arrow-container arrow-container"
            :style="!displayRight && { visibility: 'hidden', pointerEvents: 'none' }"
            @click="scrollRight"
          >
            <div
              class="arrow right-arrow"
              id="right-button"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xlg-12 vlt-contents"
      v-if="(!contents || (contents.length === 0)) && !showLoading"
    >
      <p class="no-data">{{ noData }}</p>
    </div>
    <div v-else class="content-card-div">
      <div v-for="row in contents" :key="row.contentid" class="content-image">
        <content-card
          :content="row"
          :orientation="orientation"
          :displayClose="displayClose"
          :showInfo="true"
          :fromPage="fromPage"
          :continueWatching="contentType === 'continuewatching'"
        />
      </div>
    </div>
    <app-loader
      class="loader col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xlg-12"
      v-show="showLoading"
    ></app-loader>
    <router-view />
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { EventBus } from "@/main";
import appMixins from "@/mixins/appMixins";
import FirebaseActions from "@/mixins/FirebaseActions";

export default {
  name: "ContentList",
  data() {
    return {
      contents: null,
      contentType: undefined,
      category: undefined,
      detailContent: null,
      showCategoryFilter: false,
      filterOptions: [],
      noData: "",
      showLoading: false,
      allContents: [],
      page: 0,
      pageLoaded: 0,
      flagError: false,
      title: null,
      orientation: null,
      displayClose: false,
      fromPage: "",
      flagAutologin: true,
      selectedCategory: '',
      categoryScroll: 0,
      accessDOM: { accessible: false } 
    };
  },
  computed: {
    ...mapGetters(["appMenu"]),
    categoryParentWidth(){
      if( !this.accessDOM.accessible )return 0 ;
      return this.$refs.categoryParent?.clientWidth ?? 0;
    },
    categorySliderWidth(){
      if( !this.accessDOM.accessible )return 0 ;
      return this.$refs.categorySlider?.clientWidth ?? 0;
    },
    transformWidth(){
      const slideWidth = this.categorySliderWidth - this.categoryParentWidth ;
      return slideWidth > 0 ? slideWidth : 0 ;
    },
    displayLeft(){
      return this.categoryScroll > 0
    },
    displayRight(){
      return this.categoryScroll < this.transformWidth
    }
  },
  watch: {
    $route(to, from) {
      if(to.params.contentType === from.params.contentType)return;
      this.page = 0;
      this.contents = [];
      this.allContents = [];
      this.selectedCategory = '';
      this.pageLoaded = 0;
      this.contentType = this.$route.params.contentType;
      this.getCategory(this.contentType);
    },
    contentType(newval, oldval) {
      if (oldval === "continuewatching") {
        EventBus.$off("firebaseUpdated", this.getContentContinueWatching);
        EventBus.$off("deleteContent", this.getContentContinueWatching);
      }
    },
  },
  methods: {
    ...mapActions(["actListContents", "actListCategories"]),
    actClose() {
      this.$router.push({ name: "Home" });
    },
    switchtoRelated(data) {
      if (data && data.content && this.detailContent) {
        this.detailContent = data.content;
      }
    },
    getCategory(contentType) {
      contentType = contentType.toUpperCase();
      if (this.appMenu) {
        let menu = this.appMenu;
        for (var i in menu) {
          if (menu[i].id && menu[i].id === contentType) {
            this.category = menu[i];
            break;
          }
        }
        this.title = this.category.title;
        this.orientation = this.category.screenParams.displayType;
        if (this.category) {
          this.fetchContents();
          if (this.category.hasCategoryFilter) {
            this.getFilterCategories( this.category.parameters );
          } else {
            this.showCategoryFilter = false;
            this.filterOptions = [];
          }
        }
      }
    },
    getContentContinueWatching() {
      let param = {};
      param.continuewatching = true;
      let responseData = [];
      let duration;
      let watched;
      let progress;
      this.fetchAllWatchlistContentFromFireBase(param)
        .then((response) => {
          if (response && response.length) {
            response.forEach((element) => {
              duration = element.duration;
              watched = element.watchedduration;
              progress = (watched / duration) * 100;
              if (progress > 1) {
                element.progress = progress;
              }
              responseData.push(element);
            });
          }

          this.fetchAllWatchlistSeriesFromFireBase(param).then((result) => {
            if (result.length > 0) {
              result.forEach((elementSeries) => {
                duration = elementSeries.duration;
                watched = elementSeries.watchedduration;
                progress = (watched / duration) * 100;
                if (progress > 1) {
                  elementSeries.progress = progress;
                }
                responseData.push(elementSeries);
              });
            }

            this.contents = this.sortContinueWatching(responseData);
            window.scrollTo(0, 0);
          });
        })
        .catch((error) => {
          console.log("error"), error;
        });
    },

    fetchContents() {
      this.page++;
      if (this.page === 1) {
        // this.contents = null;
        this.contents = [];
      }
      let params = {};
      params = { ...this.category.parameters };
      params.page = this.page;
      if ( this.selectedCategory ) params.category = this.selectedCategory ;
  
      this.showLoading = true;
      this.actListContents(params)
        .then((response) => {
          this.showLoading = false;
          if (response && response.length) {
            this.flagError = true;
            this.pageLoaded++;

            this.contents = response;
            let tempContent = this.allContents;
            if (tempContent.length > 1) {
              this.contents = [...tempContent, ...response];
            } else {
              this.contents = [...response];
            }
            this.allContents = this.contents;

            if (this.page === 1) window.scrollTo(0, 0);
            this.scrollFunction();
            this.detailContent = null;
          } else {
            this.noData = "No contents to display";
          }
          if (response.error && response.error === 2001 && !flagError) {
            this.contents = "";
            this.noData = response.reason;
          }
        })
        .catch((error) => {
          this.showLoading = false;
          if (error.status === 401) {
            // this.unauthorisedHandler();
            if (this.flagAutologin) {
              this.commonAutoLogin(this.fetchContents());
              this.flagAutologin = false;
              return;
            }
          }
        });
    },
    filterCategory( target, category ) {
      this.page = 0;
      this.contents = [];
      this.allContents = [];
      
      this.selectedCategory = category ?? null ;
      this.fetchContents();
      this.positionTag(target);
    },
    positionTag(target) {
      console.log( target );
      if ( !( target instanceof HTMLElement ) ) return;

      let pos = target.offsetLeft + ( target.clientWidth / 2 ) ;

      const transform = pos - ( this.categoryParentWidth / 2 );

      console.log( pos, transform )

      this.setCategoryScroll( transform );

    },
    getFilterCategories( params ) {
      this.filterOptions = [];
      this.showLoading = true;
      this.actListCategories(params)
        .then((response) => {
          this.showLoading = false;
          if (response && response.length) {
            this.filterOptions = response;
            this.showCategoryFilter = true;
            this.$nextTick( () =>{
              this.accessDOM = { accessible : true } ;
            })
            this.content = document.getElementById("content");
          } else {
            this.noData = "No contents to display";
            this.filterOptions = [];
          }
        })
        .catch((error) => {
          this.filterOptions = [];

          this.showLoading = false;
          if (error.status === 401) {
            // this.unauthorisedHandler();
            if (this.flagAutologin) {
              this.commonAutoLogin(this.getFilterCategories(contentType));
              this.flagAutologin = false;
              return;
            }
          }
        });
    },
    scrollRight() {
      const transform = this.categoryScroll + this.categoryParentWidth ;
      console.log( transform );
      this.setCategoryScroll( transform );
    },
    scrollLeft() {
      const transform = this.categoryScroll - this.categoryParentWidth ;
      this.setCategoryScroll( transform );
    },
    setCategoryScroll( val ){
      if( val < 0 ) val = 0 ;
      if( val > this.transformWidth ) val = this.transformWidth ;
      this.categoryScroll = val ;
    },
    scrollFunction() {
      let totalHeight = document.body.scrollHeight;
      document.addEventListener("scroll", () => {
        let currentScroll = window.pageYOffset;
        if (
          (currentScroll * 100) / totalHeight > 70 &&
          this.page === this.pageLoaded
        ) {
          // if ((currentScroll * 100) / totalHeight > 70) {
          if (this.allContents.length % 15 === 0) this.fetchContents();
        }
      });
    },
  },
  created() {
    this.contentType = this.$route.params.contentType;

    if (this.contentType === "continuewatching") {
      this.displayClose = true;
      this.fromPage = "continuewatching";
    } else {
      this.displayClose = false;
    }

    if (this.contentType === "continuewatching") {
      this.title = "Continue Watching";
      this.orientation = "LANDSCAPE";
      this.getContentContinueWatching();
      EventBus.$on("firebaseUpdated", this.getContentContinueWatching);
      EventBus.$on("deleteContent", this.getContentContinueWatching);
    } else {
      this.getCategory(this.contentType);
    }
    EventBus.$on("deleteContent", this.getContentContinueWatching);
    EventBus.$on("relatedContentClicked", this.switchtoRelated);
  },
  beforeDestroy() {
    if (this.contentType === "continuewatching") {
      EventBus.$off("firebaseUpdated", this.getContentContinueWatching);
      EventBus.$off("deleteContent", this.getContentContinueWatching);
    }
    EventBus.$off("relatedContentClicked", this.switchtoRelated);
  },
  mounted(){
    this.accessDOM = { accessible : true } ;
  },
  components: {
    "content-card": () =>
      import(
        /* webpackChunkName: "contentCard" */ "@/components/Shared/templates/contentCard.vue"
      ),
    "app-loader": () =>
      import(
        /* webpackChunkName: "appLoader" */ "@/components/Shared/templates/appLoader.vue"
      ),
  },
  mixins: [appMixins, FirebaseActions],
};
</script>
<style lang="scss" scoped>
@import "~sass/modules/_variables.scss";
@import "~sass/modules/_fonts.scss";
@import "~sass/modules/_mediaQueries.scss";
@import "~sass/modules/_hovereffects.scss";
// @import "~sass/modules/_aligners.scss";

.row {
  margin: 2% 0;
}
.title-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .close {
    display: block;
    cursor: pointer;
    @include closeIconHover;
  }
}
.loader {
  align-items: center;
  width: 100%;
  display: flex;
  justify-content: center;
}
.content-row {
  flex-wrap: wrap;
  display: flex;
  padding-top: 1%;
}
.vlt-category-container {
  // margin: 3em 0;
  max-width: var(--max-width);
  margin: auto;

  .container-content {
    margin-top:10px;
    margin-top: clamp(10px,3vw,40px);
  }
}
.content-title {
  text-align: left;
  font-family: $font-family;
  font-weight: $font-weight-semibold;
  font-size: 1.75rem;
  text-transform: capitalize;
  letter-spacing: 0px;
  color: $font-clr-white;
}
.no-contents-block {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  .no-contents {
    font-family: $font-family;
    font-weight: $font-weight-medium;
    color: $font-clr-white;
    font-size: 1.2rem;
    line-height: 32px;
  }
}
.outer {
  overflow-x: hidden;
  overflow-y: hidden;
  .slider{
    display: flex;
    flex-flow: row nowrap;
    width: max-content;  
  }
}
.inner {
  white-space: nowrap;
  display: inline-block;
  padding: 3px 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  p{
    display: block;
    height: max-content;
    text-align: center;
    font-family: $font-family;
    font-weight: $font-weight-semibold;
    font-size: 1rem;
  }
  &:hover {
    color: $font-clr-white;
  }
}
.paddle {
  position: absolute;
  top: 17px;
}
.lefty {
  left: -1%;
}
.righty {
  right: -1%;
}

.arrow {
  border-width: 0px 3px 3px 0px;
  border-style: solid;
  border-color: $font-clr-white-1;
  border-image: initial;
  display: inline-block;
  padding: 3px;
}
.right-arrow {
  transform: rotate(-45deg);
}
.left-arrow {
  transform: rotate(135deg);
}

.arrowAlignment {
  text-align: -webkit-center;
}
.filter-menu {
  width: 100%;
  position: relative;
  display: flex;
  flex-flow: row nowrap ;
  justify-content: space-between;
  align-items: center;
  .arrow-container{
    flex-basis: 30px;
    flex-shrink: 0;
    flex-grow: 0;
    text-align: center;
    cursor: pointer;
  }
  .category-parent{
    flex-grow: 1;
  }

  ul {
    display: -webkit-box;
    display: -ms-flexbox;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: scroll;
  }
  ul::-webkit-scrollbar {
    display: none !important;
  }

  li {
    margin-right: 2rem;
    padding: 0.2% 1.3%;
    color: $font-clr-white-1;
    cursor: pointer;
    p {
      font-family: $font-family;
      font-weight: $font-weight-semibold;
      font-size: 1rem;
    }
  }
  .active {
    color: $font-clr-white;
    background: $clr-bg-gray-dark-1 0% 0% no-repeat padding-box;
    border-radius: 6px;
  }
}
.vlt-contents {
  margin-bottom: 20px;
}
.vlt-details {
  width: 100%;
  padding: 0;
  margin-bottom: 20px;
}
.no-data {
  font-family: $font-family;
  font-weight: $font-weight-medium;
  color: $font-clr-gray;
  font-size: 1.5rem;
  line-height: 32px;
}
.content-card-div {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-gap: 45px 20px;
}
// .content-image {
//   width: 19vw;
// }
@include breakpoint(max992) {
  // .content-image {
  //   width: 26vw;
  // }
  .content-card-div {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: 15px;
  }
}
@include breakpoint(max768) {
  // .content-image {
  //   width: 38vw;
  // }
  .content-card-div {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 10px;
  }
}
</style>